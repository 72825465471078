.box--title{
	margin-bottom: 30px;
}

.box-filtre{
	margin-bottom: 50px;
}

.box-search{
	.box--title{
		margin-bottom: 15px;
	}
}
.secteurs_tools{
	margin-bottom: 30px;
} 