fieldset{
	border-color: $gray-light;
	padding: 40px 30px;
	margin-top: 50px;

	legend{
		text-transform: uppercase;
		font-weight: bold;
		padding: 0 30px;
	}
}

.input-checkbox{
	margin-right: 30px;
}

input[type=text], input[type=number]{
	background: white;
	margin: 0;
	text-indent: 15px; 
}

#filtre_text_zone{
	margin-top: 0;
}


.submit{
	input{ 
		margin: 0 15px !important; 
	}
}
 
.checkbox-container{
	height: 405px;
	border:1px solid $gray-light;
	margin:10px auto;
	overflow:auto;
}

.tous,.aucun{
	.material-icons{ 
		vertical-align: -2px;
	}
}

.select2{
  width: 100% !important;
}