@import "_materialize";
 @import "_fonts";

@import "_structure";/*
@import "_publications"; */
@import "_elements";
@import "_forms";
@import "_boxes";
@import "_navigation";

.links-secteurs .container .right {
 margin: 0 5px 0 5px!important;
}

.modal_import_debis form, .modal_import_debis button {
 margin-bottom: 0!important;
}

.modal_import_debis .actions {
 margin-top: 50px!important;
 text-align: right!important;
}

.td-flex {
 display: flex;
}

.display-none {
 display: none!important;
}

.text-error {
 color: red;
}

.text-center {
 text-align: center;
}

.modal_import_debis .progress {
 margin: 46px 0 46px 0;
 background-color: rgba(149, 10, 14, 0.2);
}

.modal_import_debis .progress .indeterminate {
 margin: 46px 0 46px 0;
 background-color: rgb(149, 10, 14);
}

.modal_import_debis input.file-path.validate {
 box-shadow: 0 1px 0 0 #950a0e;
 border-bottom: 1px solid #950a0e;
}
