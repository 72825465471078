.container {
  margin: 0 auto;
  max-width: 1280px;
  width: 90%;
}
@media #{$medium-and-up} {
  .container {
    width: 85%;
  }
}
@media #{$large-and-up} {
  .container {
    width: 70%;
  }
}
.container .row {
  margin-left: (-1 * $gutter-width / 2);
  margin-right: (-1 * $gutter-width / 2);
}

.section {
  padding-top: 1rem;
  padding-bottom: 1rem;

  &.no-pad {
    padding: 0;
  }
  &.no-pad-bot {
    padding-bottom: 0;
  }
  &.no-pad-top {
    padding-top: 0;
  }
}


.row {
  margin-left: auto;
  margin-right: auto;
  

  // Clear floating children
  &:after {
    content: "";
    display: table;
    clear: both;
  }

  .col {
    float: left;
    box-sizing: border-box;
    padding: 0 $gutter-width / 2;
    min-height: 1px;

    &[class*="push-"],
    &[class*="pull-"] {
      position: relative;
    }

    $i: 1;
    @while $i <= $num-cols {
      &.s#{$i},
      &.m#{$i},
      &.l#{$i},
      &.xl#{$i} {
        margin-left: auto;
        left: auto;
        right: auto;
      }
      $i: $i + 1;
    }

    $i: 1;
    @while $i <= $num-cols {
      $perc: unquote((100 / ($num-cols / $i)) + "%");
      &.s#{$i} {
        width: $perc;
      }
      $i: $i + 1;
    }

    $i: 1;
    @while $i <= $num-cols {
      $perc: unquote((100 / ($num-cols / $i)) + "%");
      &.offset-s#{$i} {
        margin-left: $perc;
      }
      &.pull-s#{$i} {
        right: $perc;
      }
      &.push-s#{$i} {
        left: $perc;
      }
      $i: $i + 1;
    }

    @media #{$medium-and-up} {

      $i: 1;
      @while $i <= $num-cols {
        $perc: unquote((100 / ($num-cols / $i)) + "%");
        &.m#{$i} {
          width: $perc;
        }
        $i: $i + 1
      }

      $i: 1;
      @while $i <= $num-cols {
        $perc: unquote((100 / ($num-cols / $i)) + "%");
        &.offset-m#{$i} {
          margin-left: $perc;
        }
        &.pull-m#{$i} {
          right: $perc;
        }
        &.push-m#{$i} {
          left: $perc;
        }
        $i: $i + 1;
      }
    }

    @media #{$large-and-up} {

      $i: 1;
      @while $i <= $num-cols {
        $perc: unquote((100 / ($num-cols / $i)) + "%");
        &.l#{$i} {
          width: $perc;
        }
        $i: $i + 1;
      }

      $i: 1;
      @while $i <= $num-cols {
        $perc: unquote((100 / ($num-cols / $i)) + "%");
        &.offset-l#{$i} {
          margin-left: $perc;
        }
        &.pull-l#{$i} {
          right: $perc;
        }
        &.push-l#{$i} {
          left: $perc;
        }
        $i: $i + 1;
      }
    }

    @media #{$extra-large-and-up} {

      $i: 1;
      @while $i <= $num-cols {
        $perc: unquote((100 / ($num-cols / $i)) + "%");
        &.xl#{$i} {
          width: $perc;
        }
        $i: $i + 1;
      }

      $i: 1;
      @while $i <= $num-cols {
        $perc: unquote((100 / ($num-cols / $i)) + "%");
        &.offset-xl#{$i} {
          margin-left: $perc;
        }
        &.pull-xl#{$i} {
          right: $perc;
        }
        &.push-xl#{$i} {
          left: $perc;
        }
        $i: $i + 1;
      }
    }
  }
}
