@font-face {
    font-family: 'nunito';
    src: url('fonts/nunitoSans/nunitosans-bold.eot');
    src: url('fonts/nunitoSans/nunitosans-bold.eot?#iefix') format('embedded-opentype'),
         url('fonts/nunitoSans/nunitosans-bold.woff') format('woff'),
         url('fonts/nunitoSans/nunitosans-bold.ttf') format('truetype'),
         url('fonts/nunitoSans/nunitosans-bold.svg#nunito_sansbold') format('svg');
    font-weight: 700;
    font-style: normal;
}

@font-face {
    font-family: 'nunito';
    src: url('fonts/nunitoSans/nunitosans-bolditalic.eot');
    src: url('fonts/nunitoSans/nunitosans-bolditalic.eot?#iefix') format('embedded-opentype'),
         url('fonts/nunitoSans/nunitosans-bolditalic.woff') format('woff'),
         url('fonts/nunitoSans/nunitosans-bolditalic.ttf') format('truetype'),
         url('fonts/nunitoSans/nunitosans-bolditalic.svg#nunito_sansbold_italic') format('svg');
    font-weight: 700;
    font-style: italic;

}

@font-face {
    font-family: 'nunito';
    src: url('fonts/nunitoSans/nunitosans-italic.eot');
    src: url('fonts/nunitoSans/nunitosans-italic.eot?#iefix') format('embedded-opentype'),
         url('fonts/nunitoSans/nunitosans-italic.woff') format('woff'),
         url('fonts/nunitoSans/nunitosans-italic.ttf') format('truetype'),
         url('fonts/nunitoSans/nunitosans-italic.svg#nunito_sansitalic') format('svg');
    font-weight: normal;
    font-style: italic;

}

@font-face {
    font-family: 'nunito';
    src: url('fonts/nunitoSans/nunitosans-regular.eot');
    src: url('fonts/nunitoSans/nunitosans-regular.eot?#iefix') format('embedded-opentype'),
         url('fonts/nunitoSans/nunitosans-regular.woff') format('woff'),
         url('fonts/nunitoSans/nunitosans-regular.ttf') format('truetype'),
         url('fonts/nunitoSans/nunitosans-regular.svg#nunito_sansregular') format('svg');
    font-weight: normal;
    font-style: normal;

}

@font-face {
    font-family: 'nunito';
    src: url('fonts/nunitoSans/nunitosans-semibold.eot');
    src: url('fonts/nunitoSans/nunitosans-semibold.eot?#iefix') format('embedded-opentype'),
         url('fonts/nunitoSans/nunitosans-semibold.woff') format('woff'),
         url('fonts/nunitoSans/nunitosans-semibold.ttf') format('truetype'),
         url('fonts/nunitoSans/nunitosans-semibold.svg#nunito_sanssemibold') format('svg');
    font-weight: 600;
    font-style: normal;

}

@font-face {
    font-family: 'nunito';
    src: url('fonts/nunitoSans/nunitosans-semibolditalic.eot');
    src: url('fonts/nunitoSans/nunitosans-semibolditalic.eot?#iefix') format('embedded-opentype'),
         url('fonts/nunitoSans/nunitosans-semibolditalic.woff') format('woff'),
         url('fonts/nunitoSans/nunitosans-semibolditalic.ttf') format('truetype'),
         url('fonts/nunitoSans/nunitosans-semibolditalic.svg#nunito_sanssemibold_italic') format('svg');
    font-weight: 600;
    font-style: italic;

}

.nunito{
    font-family: 'nunito', 'Roboto', Arial, Helvetica, sans-serif;
}