.flex-center{
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-align-content: stretch;
    -ms-flex-line-pack: stretch;
    align-content: stretch;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
}

address{
    font-weight: normal;
    font-style: normal;
}
.mb30{
    margin-bottom: 30px;
}

.pb30{
    padding-bottom: 30px;
}

.mb15{
    margin-bottom: 15px;
} 
.mt15{
    margin-top: 15px;
} 
.pa0{
    padding: 0;
}
 
.pr0{
    padding-right: 0; 
}
.w50{
    width: 50%;
}

.content-table{
    background: $gray-light;
    padding: 35px 0;
}

a{
    cursor: pointer;
}
a:hover{
    color : #950a0e;
}
.thactions{
    text-align: center;
    a{
        padding: 0 8px;
        display: inline-block;

        & + a{
            border-left: 1px solid $link-color;
        }
        

        i{
            color:$link-color;
        }
    }
}


.multi-checkbox, .is-flex{
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex; 
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -webkit-align-content: stretch;
    -ms-flex-line-pack: stretch;
    align-content: stretch;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
}  

.table-secteurs{
    th{
        cursor: pointer;
    }
}

.message{
    font-weight: 600;
    text-transform: uppercase;
    font-size: 14px;
    padding: 5px 10px;
    
}

.error{
    @extend .message;
    color:white;
    background-color: $primary-color;
    
}
.btn-icon-search{
    padding: 0 ;
    width: 50px;
    height: 50px;
    margin-left: -10px;
    text-align: center;
    i{
        font-size: 25px;
    }
}


.list-perso{
    li{
        position: relative;

        &:before{
            content:"";
            display: inline-block;
            width: 8px;
            height: 8px;
            border-radius: 100%;
            background: $primary-color;
            margin-right: 8px;
        }
    }
}

.list-legend{
    li{
        display: inline-block;
        padding:0 10px;
        img{
            vertical-align: -2px;
        }

        & + li{
            border-left:1px solid $gray-dark;
        }
    }
}

#content_onglet_zone{
        border: 2px solid #63696d;
    background: #fff;
}

.map_conteneur{
    padding: 15px 0;
}
.bilan_conteneur{
    padding: 15px;
}

.prise-detail{
    padding: 0;
    dd{
        margin:0 0 5px;
    } 

    .label{
        font-weight: bold;
    }
} 

.etat-bloc{
    padding:15px 0; 
    margin: 15px 0 45px;
    background-color: $gray-light; 
}

.block{
    display: block !important ;
}

ul.localisation{
    li{
        margin-bottom: 15px;
    }
}
.geoloc{
    margin-bottom: 40px;
}

.pa15{
    padding: 15px;
}
.ml15{
    margin-left: 15px;
}
.darken-1{
    table{
        border-color:#ffffff;
    }
}
/*
.grey.darken-1{
    color: white;
}*/

.icon-logout{
    width: 30px;
    font-size: 20px;
    height: 30px;
    border-radius:100%;
    border:1px solid $brand-warning;
    text-align: center;
    line-height: 30px;
    color:$brand-warning !important; 
    position: absolute;
    top: 15px;
    right: -30px;

}


.italic{
    font-style: oblique;
}
.mr10{
    margin-right: 10px;
}
.mt30{
    margin-top: 30px;
}
.prise-info{ 
    h2{
        a{
            color:$primary-color; 
            text-decoration: underline;
        }
    }
}

.grey-bloc{
    background:$gray-light; 
}  

.suivi{
    &.grey-bloc{
        padding-top: 30px;
    }
}

.tab-active{
    &.active{
        padding:30px 15px;
        border:2px solid $gray-dark;
    }
}

#secteurs_infos{
    .collection-item{
        padding: 0;

    span{
        max-width: 70%;
    }
}
}

.collection-item{
    padding:5px;


}

.is-flex-between{
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-align-content: center;
    -ms-flex-line-pack: center;
    align-content: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
}

td.actions{
    width:20%;
    span{
        display: inline-block;
        vertical-align: middle;
    }
    .cancel{
        float: right; 
         display: inline-block;
        vertical-align: middle;
    }
} 


.wauto{
    width: auto !important;
    margin:0 10px;
} 
.form-group{
   display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-flex-wrap: nowrap;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -webkit-align-content: stretch;
    -ms-flex-line-pack: stretch;
    align-content: stretch;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    /* margin-bottom: 15px; */
     label{
        color:rgba(0,0,0,.87);
        font-size: 16px;
    }
}

table.bordered>tbody>tr{
    &:last-child{
        border-bottom:2px solid #6d7478;
    }
}

.f18{
    font-size: 18px;
}

.is-calque{
    max-height: 400px;
    overflow: auto;

    input{
        max-width: 100px;
        margin-right: 10px !important;
    }
}

.operation_list{
   
    padding: 0;
    
    
} 
.list-operations{
    border:none;
    border-left:1px solid #d0d0d0;
    
    max-height: 400px;
    overflow: auto;
    display: block;
    tbody{
        width: 100%;
        display: table;
    }

    tr{
        &:nth-child(even){
            background:#f0f0f1; 
        }
    }
}


.table-form-group{
    .select-wrapper, input{
        width: 50%;
    }
}

.mw80{
    min-width: 80%;
}
.next-prev{
    margin-bottom: 30px;
}

.btn-prev, .btn-next{

    i{
        vertical-align: -6px;
        color:$primary-color;
    }

    &:hover,&:focus{
        i{
            color:$gray-base;
        }
    }
}


.delete-icon{
    position: absolute;
    top: 5px;
    right: 8px;
    i{
        font-size: 20px;
    }
}
.bloc-comments{
    .collection-item {
        position: relative;
        padding: 20px 15px 15px;
    }
}