body {
  	margin: 0;
	display: flex;
	flex-direction: column;
	min-height: 100vh;
}


.header{
	padding:15px 0;
	background: $primary-color;


}

.footer{
	margin-top: auto;
	font-size: 14px;
	padding: 15px 0 0;
	border-top: 2px solid $gray-light;

	.container{
		position: relative;
		

		&:before{
			content:"";
			display: block;
			background: url('../images/img-footer.png')no-repeat 0 0;
			width: 56px;
			height: 74px;
			position: absolute;
			left: 15px;
			bottom: 0;
		}
	}

	a{
		text-decoration: underline;
	}
}
.main{
	margin: auto 0;
}

.brand-logo{
	max-width:400px;
	display: block;
	img{
		width: 100%;
	}

	@media(max-width:1280px){
		width:280px;
	}
}